@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,400;12..96,800&family=Inria+Serif:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap');


* {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;   
    -ms-user-select: none;     
    user-select: none;        
  }